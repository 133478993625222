import {
  NETWORK_SUCCESS,
  GET_CURRENT_MOVEPLAN,
  GET_VALID_DATES,
  UPDATE_MOVE_PLAN,
  UPDATE_BOX_PURCHASES,
  UPDATE_PACKING_SERVICES,
  UPDATE_MOVE_DATE,
  CHECK_VALID_DATES,
  CHECK_MOVER_AVAILABILITY,
  PREPARE_RESCHEDULE_REQUEST,
  EMAIL_PLAN,
  NETWORK_FAILURE,
  ADDRESS_UPDATE, 
  REQUEST_INSURANCE, 
  CANCEL_MOVE, 
  CREATE_MOVEPLAN, 
  SUBMIT_DNA, 
  SET_CURRENT_MOVEPLAN_ID, 
  CONTINUE_LAST_PLAN, 
  SUBMIT_UNSERVED_EMAIL, 
  UPDATE_MOVE_TIME,
  REMOVE_BOX_PURCHASES,
  INIT_REVIEW_PAGE
} from '../actionTypes';
import {apiRequest} from "../actions/api";
import {
  checkValidDates, clearBoxPurchases,
  getCurrentMoveplan, getValidDates,
  setCurrentPlanInfo, setMoveplanInsurance,
  setValidDates, updateMovePlan, setCurrentMoveplanID
} from '../actions/movePlan';
import { openModal, closeAllModals, closeModal } from '../actions/modals';
import { setEmailError, setEmailSent, setPlanUpdating } from '../actions/plan';
import { push } from "connected-react-router";
import { setDetailsLoading } from '../actions/details';
import has from 'lodash-es/has';
import { setInventoryLoading, setAddedTypicals, fetchInventory } from '../actions/inventory';
import { isValidMovedate } from '../reducers/movePlan';
import { createMovePlan, setMoveplanLoading, setDnaError } from '../actions/movePlan';
import { track } from '../actions/analytics';
import { HOME_SIZES } from '../../constants';
import { setLastMPID } from '../actions/auth';
import { setLastStep, resetFunnel, importFunnelState, nextStep, setCurrentStep } from '../actions/funnel';
import { getBids, setCompareLoading } from '../actions/compare';
import { checkPromo } from '../actions/book';

const movePlanMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);

  if (action.type === INIT_REVIEW_PAGE) {
    dispatch(setCurrentStep('REVIEW'));
    dispatch(setCurrentMoveplanID(action.payload));
    dispatch(getCurrentMoveplan());
    dispatch(fetchInventory(action.payload));
  }
  if (action.type === SET_CURRENT_MOVEPLAN_ID) {
    //dispatch(setLastMPID(action.payload.movePlanId));
  }

  if (action.type === CREATE_MOVEPLAN) {
    dispatch(setMoveplanLoading(true));
    dispatch(setAddedTypicals(false));
    dispatch(apiRequest({
      url: `${process.env.REACT_APP_API_URL}/move_plans`,
      method: 'POST',
      params: {
        move_plan: action.payload
      },
      fromAction: CREATE_MOVEPLAN
    }))
  }
  
  if (action.type === GET_CURRENT_MOVEPLAN) {
    dispatch(setMoveplanLoading(true));
    const mpid = !!action.meta.useLast ? getState().auth.lastMPID : getState().movePlan.currentMPID;
    dispatch(
      apiRequest({
        url: `${process.env.REACT_APP_API_URL}/move_plans/${mpid}.json`,
        method: 'GET',
        fromAction: GET_CURRENT_MOVEPLAN,
        redirect: has(action.meta, 'redirect') ? action.meta.redirect : false 
      })
    );
  }
  if (action.type === GET_VALID_DATES) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/valid_dates`,
        method: 'GET',
        params: {
          move_date: getState().movePlan.currentPlan.details.move_date
        },
        fromAction: GET_VALID_DATES
      })
    );
  }

  if (action.type === UPDATE_MOVE_PLAN) {
    let updateParams = {};
    Object.keys(action.payload).forEach( key => {
      updateParams[key] = action.payload[key];
    });
    
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}`,
        method: 'PATCH',
        params: updateParams,
        fromAction: UPDATE_MOVE_PLAN,
        redirect: !!action.meta ? action.meta : false
      })
    )
  }

  if (action.type === UPDATE_BOX_PURCHASES) {
    dispatch(setMoveplanLoading(true));
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/update_box_delivery`,
        method: 'POST',
        params: {
          boxes: action.payload.boxPurchases,
          delivery_date: action.payload.date
        },
        fromAction: UPDATE_BOX_PURCHASES
      })
    );
  }

  if (action.type === REMOVE_BOX_PURCHASES) {
    let temp = getState().movePlan.currentPlan.box_purchases;
    temp.map( boxPurchase => {
      boxPurchase.quantity = 0;
      return true;
    });
    dispatch(
      updateMovePlan({
        move_plan: {
          box_delivery_date: null
        }
      })
    );
  }
  if (action.type === UPDATE_PACKING_SERVICES) {
    // 1= packing
    // 2= packing & unpacking
    // 4= pack yourself
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/update_packing`,
        method: 'PATCH',
        params: {
          follow_up_packing_service_id: action.payload
        },
        fromAction: UPDATE_PACKING_SERVICES
      })
    );
  }
  if (action.type === UPDATE_MOVE_DATE) {
    dispatch(setCompareLoading(true));
    dispatch(
      updateMovePlan({
        move_plan: {
          move_date: action.payload.move_date
        }
      })
    )
  }

  if (action.type === UPDATE_MOVE_TIME) {
    dispatch(setCompareLoading(true));
    dispatch(
      updateMovePlan({
        move_time: action.payload.move_time
      })
    )
  }
  if (action.type === CHECK_VALID_DATES) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/valid_dates`,
        method: 'GET',
        params: {
          move_date: action.payload
        },
        fromAction: CHECK_VALID_DATES
      })
    );
  }
  if (action.type === CHECK_MOVER_AVAILABILITY) {
    dispatch(setDetailsLoading(true));
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/estimates/for_move_dates`,
        method: 'GET',
        params: action.payload,
        fromAction: CHECK_MOVER_AVAILABILITY
      })
    );
  }
  if (action.type === PREPARE_RESCHEDULE_REQUEST) {
    dispatch(checkValidDates)
  }
  if (action.type === EMAIL_PLAN) {
    dispatch(setMoveplanLoading(true));
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${!!getState().movePlan.currentMPID ? getState().movePlan.currentMPID : getState().auth.lastMPID}/email`,
        method: 'POST',
        params: { email: action.payload },
        fromAction: EMAIL_PLAN
      })
    );
  }
  if (action.type === REQUEST_INSURANCE) {
    dispatch(apiRequest({
      url: `/move_plans/${getState().movePlan.currentMPID}/request_insurance`,
      method: 'POST',
      fromAction: REQUEST_INSURANCE
    }))
  }

  if (action.type === ADDRESS_UPDATE) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}`,
        method: 'PATCH',
        params: {
          address_update: true,
          move_plan: {
            ...getState().movePlan.currentPlan.details,
            [action.payload.addressKey]: action.payload.route
          }
        },
        fromAction: ADDRESS_UPDATE
      })
    )
  }

  if (action.type === REQUEST_INSURANCE) {
    dispatch(setPlanUpdating(true));
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/request_insurance`,
        method: 'POST',
        fromAction: REQUEST_INSURANCE
      })
    )
  }

  if(action.type === CANCEL_MOVE) {
   dispatch(openModal({ name: 'loader', properties: { state: 'initial' }}))
   dispatch(
     apiRequest({
       url: `api/v3/move_plans/${getState().movePlan.currentMPID}/cancel_job`,
       method: 'POST',
       fromAction: CANCEL_MOVE
     })
   )
  }

  if(action.type === SUBMIT_DNA) {
    dispatch(setMoveplanLoading(true));
    dispatch(track('Move plan started', {
      extra_pick_up: !!action.payload.extra_pick_up_enabled,
      extra_drop_off: !!action.payload.extra_drop_off_enabled,
      home_size: HOME_SIZES[action.payload.home_size_id].type,
      added_storage: action.payload.storage_in_transit || action.payload.move_into_storage,
      created_device_type: getState().ui.mobile.isMobile ? "mobile" : "desktop"
    }));
    dispatch(createMovePlan(action.payload));
    dispatch(resetFunnel());
    sessionStorage.setItem('addedTypicals', false);
  }

  if(action.type === CONTINUE_LAST_PLAN) {
    dispatch(openModal({ name: 'loader', properties: { state: 'initial' }}));
    dispatch(getCurrentMoveplan({ redirect: 'last_step'}));
  }

  if(action.type === SUBMIT_UNSERVED_EMAIL) {
    dispatch(setMoveplanLoading(true));
    dispatch(
      apiRequest({
        url: `api/v3/unserved_users`,
        method: 'POST',
        params: { 
          move_plan_id: getState().movePlan.currentMPID,
          unserved_user: { email: action.payload }
        },
        fromAction: SUBMIT_UNSERVED_EMAIL
      })
    );
  }

  // if(action.type === SUBMIT_RESCHEDULE_REQUEST) {
  //   dispatch(checkMoverAvailability(params));
  //   if (!!this.props.services.box_delivery) {
  //     if (this.props.validDates.is_at_least_5_business_days_away){
  //       if (this.isBoxDeliveryDateValid()){
  //         this.props.updateMovePlan(params);
  //       } else { //box delivery needs changing
  //         if (this.boxDeliveryNeedsUpdate()) {
  //           params.box_delivery_date = this.props.validDates.max_box_delivery_date;
  //           this.props.updateMovePlan(params);
  //         } else this.props.updateMovePlan(params);
  //       }
  //     } else { //box delivery needs changing
  //       params.box_delivery_date = this.props.validDates.max_box_delivery_date;
  //       this.props.updateMovePlan(params);
  //     }
  //   } else this.props.updateMovePlan(params);
  //   this.props.closeModal()
  // }

  if (action.type === NETWORK_SUCCESS) {
    switch (action.meta.originalRequest.fromAction) {
      case CREATE_MOVEPLAN:
        const resp = action.payload.response.data;
        if(!!resp.uuid) {
          dispatch(track('Move Plan', {uuid: resp.uuid}));
          dispatch(setCurrentMoveplanID(resp.uuid));
          dispatch(setLastMPID(resp.uuid));
          dispatch(resetFunnel());
        } else {
          //dispatch(setLastMPID(null));
        }
        if(resp.movers_found && !resp.movers_all_consult_only) {
          dispatch(setMoveplanLoading(false));
          dispatch(push(`moving/${action.payload.response.data.uuid}/overview`));
          
        } else {
          dispatch(setCurrentMoveplanID(action.payload.response.data.uuid))
          dispatch(getCurrentMoveplan());
          dispatch(setDnaError(resp));
        }     
        break;
      case UPDATE_MOVE_PLAN:
        dispatch(setCurrentPlanInfo(action.payload.response.data.plan));
        dispatch(getValidDates());
        if(getState().funnel.currentStep === 'COMPARE') dispatch(getBids());
        if(!!action.meta.originalRequest.redirect) dispatch(nextStep());
        dispatch(setCompareLoading(false)); 
        break;
      case GET_CURRENT_MOVEPLAN:
        dispatch(setCurrentPlanInfo(action.payload.response.data.plan));
        dispatch(getValidDates());
        if(getState().funnel.currentStep === 'DETAILS') {
          dispatch(setDetailsLoading(false));
        }
        if(has(getState().movePlan.currentPlan, 'mover') && getState().funnel.currentStep === 'INVENTORY') {
          dispatch(setInventoryLoading(false));
        }
        if(has(action.meta.originalRequest, 'redirect')) {
          if(action.meta.originalRequest.redirect === 'last_step') {
            if(getState().router.location.pathname === "/myhome") {
              dispatch(importFunnelState(action.payload.response.data.plan.last_step));
              if(action.payload.response.data.plan.last_step === 'congrats') {
                dispatch(push(`/moving/${getState().movePlan.currentMPID}/plan`));
              } else {
                dispatch(push(`/moving/${getState().movePlan.currentMPID}/${action.payload.response.data.plan.last_step}`));
              }
            } else if(action.payload.response.data.plan.is_booked) {
              window.location.href = `${process.env.REACT_APP_MARKETING_URL}/moving/${action.payload.response.data.plan.uuid}/plan`
            } else {
              dispatch(push(`/moving/${getState().movePlan.currentMPID}/${action.payload.response.data.plan.last_step}`));
            }
          }
          if(action.meta.originalRequest.redirect === 'old_site') {
            window.location.href = `${process.env.REACT_APP_MARKETING_URL}/moving/${getState().movePlan.currentMPID}/${action.payload.response.data.plan.last_step}`;
          }
          if(action.meta.originalRequest.redirect === 'inventory') {
            dispatch(push(`/moving/${getState().movePlan.currentMPID}/inventory`));
          }
          if(action.meta.originalRequest.redirect === 'inventory-continue') {
            dispatch(push(`/moving/${getState().movePlan.currentMPID}/inventory`));
          }
          if(!!action.payload.response.data.plan.promo_code && !has(getState().analytics.couponData, 'coupon')) {
            dispatch(checkPromo(action.payload.response.data.plan.promo_code));
          }
        }
        
        dispatch(setMoveplanLoading(false));
        const last_step = action.payload.response.data.plan.last_step.toUpperCase();
        if(last_step !== getState().funnel.lastStep) {
          dispatch(setLastStep(action.payload.response.data.plan.last_step.toUpperCase()));
        }
        if(getState().funnel.currentStep === 'COMPARE') {
          dispatch(setCompareLoading(true));
          dispatch(getBids());
        }
        if(!isValidMovedate(getState().movePlan, getState().auth.isAdmin) && !action.payload.response.data.plan.is_booked) {
          dispatch(openModal({ name: 'moveDate' }))
        }
        if(getState().funnel.currentStep === 'INVENTORY'
          && action.payload.response.data.plan.details.home_size_id <= 7 
          && !action.payload.response.data.plan.is_booked
          && sessionStorage.getItem('addedTypicals') !== 'true'
          ) {
          sessionStorage.setItem('addedTypicals', true);
          dispatch(openModal({  name:"typicals", properties: { position: 'top' }}));
        }
        break;
      case GET_VALID_DATES:
        dispatch(setValidDates(action.payload.response.data));
        break;
      case UPDATE_PACKING_SERVICES:
        // This seems to already remove box purchases when you select anything other than packyourself
        // if((action.meta.originalRequest.params.follow_up_packing_service_id === "1"
        //   || action.meta.originalRequest.params.follow_up_packing_service_id === "2")
        //   && hasBoxPurchases(getState().movePlan)){
        //   dispatch(updateBoxPurchases(null,null));
        // } else {
        //   dispatch(getCurrentMoveplan());
        // }
        dispatch(getCurrentMoveplan());
        break;
      case CHECK_VALID_DATES:
        dispatch(setValidDates(action.payload.response.data));
        break;
      case CHECK_MOVER_AVAILABILITY:
        const date = Object.keys(action.payload.response.data)[0];
        const props = {
          ...action.meta.originalRequest.params,
          price: action.payload.response.data[date]
        }
        dispatch(openModal({ name: 'requestReschedule', properties: props}));
        dispatch(setDetailsLoading(false));
        break;
      case EMAIL_PLAN:
        dispatch(setEmailError(false));
        dispatch(setEmailSent(true));
        dispatch(setMoveplanLoading(false));
        break;
      case ADDRESS_UPDATE:
        dispatch(setCurrentPlanInfo(action.payload.response.data.plan));
        break;
      case REQUEST_INSURANCE:
        dispatch(setMoveplanInsurance(true));
        dispatch(setPlanUpdating(false));
        break;
      case CANCEL_MOVE:
        dispatch(getCurrentMoveplan());
        if(getState().funnel.currentStep === 'BOOK' || getState().funnel.currentView === 'plan') {
          dispatch(push(`/moving/${getState().movePlan.currentMPID}/compare`));
        }
        break;
      case UPDATE_BOX_PURCHASES:
        if(action.meta.originalRequest.params.box_delivery_date === null) {
          dispatch(clearBoxPurchases())
        }
        dispatch(getCurrentMoveplan());
        dispatch(closeModal());
        break;
      default:
        break;
    }
  }

  if (action.type === NETWORK_FAILURE) {
    switch (action.meta.originalRequest.fromAction) {
      case CREATE_MOVEPLAN:
        dispatch(track('DNA create plan failed'))
        dispatch(setMoveplanLoading(false));
        break;
      case EMAIL_PLAN:
        dispatch(setEmailError(true));
        dispatch(setEmailSent(false));
        break;
      case REQUEST_INSURANCE:
        dispatch(setPlanUpdating(false));
        alert('there was a problem adding insurance to this move plan');
        break;
      case GET_CURRENT_MOVEPLAN:
        // Account for the case where cookies are deleted but local storage remains
        if(has(action.meta.originalRequest, 'redirect')) {
          if(action.meta.originalRequest.redirect === 'inventory-continue') {
            dispatch(closeAllModals());
            dispatch(openModal({ name: 'authModal', properties: { authModalView: 'login', redirect: 'inventory' } }));
          }
        }
        break;
      case CHECK_MOVER_AVAILABILITY:
        dispatch(setDetailsLoading(false));
        break;
      default:
        break;
    }
  }
};

export default movePlanMiddleware;
