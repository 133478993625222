import React, { useState } from 'react';
import {connect} from 'react-redux';
import { signOut } from '../../redux/actions/auth';
import { Link } from 'react-router-dom';
import has from 'lodash-es/has';

const mapStateToProps = ({auth}) => ({
  isLoggedIn: has(auth, 'user'),
  currentUser: has(auth, 'user') ? auth.user : {}
})

const Header = props => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMobileMenu = () => setShowMenu(!showMenu)
  const menuItemClick = () => setShowMenu(false)
  const signOut = () => {
    setShowMenu(!showMenu);
    props.signOut();
  }
  return (
    <div className={showMenu ? 'funnel-sidebar-open mobile-menu-open' : ''}>
      <section className="header-container">
        {/* <a className="logo-container left" rel="canonical" onClick={menuItemClick} href={ process.env.REACT_APP_MARKETING_URL} title="Unpakt">
          <svg className="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 555">
            <path d="M685.2 348.7h38.6V152h-38.6v196.7zm-361-133.4a60.2 60.2 0 0 0-42.7-18.3c-15.7 0-33.5 9.2-43.2 21v-16.3h-36.2v147h38.6V282c0-11.6-.6-27.5 5.8-37.6 4.9-7.9 15.3-13 25-13 9.2 0 18.4 4.5 23.5 12.2 6.2 9.8 6 24.3 6 35.7v69.4h38.7v-76.3c0-18.8-.2-37-11.8-52.9a28.1 28.1 0 0 0-3.7-4.2zm111.6-19c-20.2 0-35.6 7.1-45.2 23.7v-18.2h-36.3v201.4h38.6v-71.9c11.6 14.6 26.6 21.3 42.8 21.1 26.2-.4 43.7-9.5 56.6-24.6 11.9-14 18.6-33.1 18.6-53.5s-5.4-37.3-19.1-52.7c-15.1-17.2-32.2-25.4-56-25.4zM432 317.9c-25.1 0-41.5-19.3-41.5-43.6s16.5-43.7 41.6-43.7c26.4 0 43.1 19.3 43.1 45-.1 23.7-17.8 42.3-43.2 42.3zm204.9-98c-9.7-16.6-25-23.7-45.2-23.7-23.8 0-41 8.2-56.1 25.4-13.8 15.4-19.2 32.3-19.2 52.7s6.8 39.4 18.6 53.5c13 15.1 30.4 24.2 56.6 24.6 16.3.3 31.8-6.5 45.2-21.1v17.4H673V201.8h-36.2V220zm-41.5 98c-25.4 0-43.1-18.6-43.1-42.3 0-25.7 16.7-45 43.1-45 25.1 0 41.6 19.4 41.6 43.7s-16.5 43.6-41.6 43.6zm225-116.2H777l-45.9 63.1V282l45.9 66.7h45.8l-57.4-74.4zm45.9-49.7h-38.6v196.7h38.6V237.3h31.4v-35.5h-31.4V152zM149.4 274c0 9-.3 21.4-4.6 29.9-4.7 9.3-14 14.7-24.7 15-10.7-.3-20-5.7-24.7-15-4.5-8.4-4.7-20.9-4.7-29.9l.3-72.2H52.4v75.8c0 18.8 3.2 41.4 16.7 55.5 12.4 13.8 29.4 20.4 47.2 20.4h7.4c17.8 0 34.8-6.6 47.2-20.4 13.5-14 16.7-36.4 16.7-55.2v-76.1H149zm790.9-107.1h-5.1v10.2H932v-25.7h10.3c4.7 0 8 3 8 7.7a7.1 7.1 0 0 1-6.7 7.5l6.9 10.5h-3.8zm1.7-12.7h-6.7v9.8h6.7a4.9 4.9 0 1 0 0-9.8z" fill="#fff"/>
            <path d="M941.2 190.3a26 26 0 1 1 26-26 26.1 26.1 0 0 1-26 26zm0-48.9a22.8 22.8 0 1 0 22.8 22.8 22.9 22.9 0 0 0-22.8-22.8z" fill="#fff"/>
          </svg>
        </a> */}
        <Link className="logo-container left" to="/">
          <svg className="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 555">
            <path d="M685.2 348.7h38.6V152h-38.6v196.7zm-361-133.4a60.2 60.2 0 0 0-42.7-18.3c-15.7 0-33.5 9.2-43.2 21v-16.3h-36.2v147h38.6V282c0-11.6-.6-27.5 5.8-37.6 4.9-7.9 15.3-13 25-13 9.2 0 18.4 4.5 23.5 12.2 6.2 9.8 6 24.3 6 35.7v69.4h38.7v-76.3c0-18.8-.2-37-11.8-52.9a28.1 28.1 0 0 0-3.7-4.2zm111.6-19c-20.2 0-35.6 7.1-45.2 23.7v-18.2h-36.3v201.4h38.6v-71.9c11.6 14.6 26.6 21.3 42.8 21.1 26.2-.4 43.7-9.5 56.6-24.6 11.9-14 18.6-33.1 18.6-53.5s-5.4-37.3-19.1-52.7c-15.1-17.2-32.2-25.4-56-25.4zM432 317.9c-25.1 0-41.5-19.3-41.5-43.6s16.5-43.7 41.6-43.7c26.4 0 43.1 19.3 43.1 45-.1 23.7-17.8 42.3-43.2 42.3zm204.9-98c-9.7-16.6-25-23.7-45.2-23.7-23.8 0-41 8.2-56.1 25.4-13.8 15.4-19.2 32.3-19.2 52.7s6.8 39.4 18.6 53.5c13 15.1 30.4 24.2 56.6 24.6 16.3.3 31.8-6.5 45.2-21.1v17.4H673V201.8h-36.2V220zm-41.5 98c-25.4 0-43.1-18.6-43.1-42.3 0-25.7 16.7-45 43.1-45 25.1 0 41.6 19.4 41.6 43.7s-16.5 43.6-41.6 43.6zm225-116.2H777l-45.9 63.1V282l45.9 66.7h45.8l-57.4-74.4zm45.9-49.7h-38.6v196.7h38.6V237.3h31.4v-35.5h-31.4V152zM149.4 274c0 9-.3 21.4-4.6 29.9-4.7 9.3-14 14.7-24.7 15-10.7-.3-20-5.7-24.7-15-4.5-8.4-4.7-20.9-4.7-29.9l.3-72.2H52.4v75.8c0 18.8 3.2 41.4 16.7 55.5 12.4 13.8 29.4 20.4 47.2 20.4h7.4c17.8 0 34.8-6.6 47.2-20.4 13.5-14 16.7-36.4 16.7-55.2v-76.1H149zm790.9-107.1h-5.1v10.2H932v-25.7h10.3c4.7 0 8 3 8 7.7a7.1 7.1 0 0 1-6.7 7.5l6.9 10.5h-3.8zm1.7-12.7h-6.7v9.8h6.7a4.9 4.9 0 1 0 0-9.8z" fill="#fff"/>
            <path d="M941.2 190.3a26 26 0 1 1 26-26 26.1 26.1 0 0 1-26 26zm0-48.9a22.8 22.8 0 1 0 22.8 22.8 22.9 22.9 0 0 0-22.8-22.8z" fill="#fff"/>
          </svg>
        </Link>
        <nav className="mobile-navbar">
          <div className="menu__level menu__level--current">
              <div className="menu__wrap">
              { props.isLoggedIn &&
                <div className="mobile-users-section" ng-if="isLoggedIn()">
                  <div className="mobile-user-name-holder">
                    <span className="mobile-user-name">{ !!props.currentUser.name ? props.currentUser.name : props.currentUser.email }</span>
                  </div>
                  <ul className="mobile-menu mobile-user-menu">
                    <li className="mobile-menu-item" onClick={menuItemClick}>
                      <a href={ process.env.REACT_APP_SUPPORT_URL + '/my-home'} className="mobile-menu-item-link" target="_self">My moves</a>
                    </li>
                    <li className="mobile-menu-item" onClick={menuItemClick} ng-if="isAdmin()">
                      <a href={ process.env.REACT_APP_BASE_API_URL + '/admin'} className="mobile-menu-item-link" target="_self">Admin Dashboard</a>
                    </li>
                    <li className="mobile-menu-item" onClick={menuItemClick}>
                      <a href={ process.env.REACT_APP_MARKETING_URL + '/users/edit'} className="mobile-menu-item-link" target="_self">Account Settings</a>
                    </li>
                    <li className="mobile-menu-item" onClick={menuItemClick}>
                      <a className="dropdown-menu-item-link mobile-menu-benefits-link" onClick={menuItemClick} 
                      target="_self" href={process.env.REACT_APP_MARKETING_URL + '/benefits'}>Get $20</a>
                    </li>
                  </ul>
                </div>
              }
              <ul className="mobile-menu mobile-main-menu">
                {/* <li className="mobile-menu-item active">
                  <a className="mobile-menu-item-link active" onClick={menuItemClick} href={ process.env.REACT_APP_MARKETING_URL}>Find a moving company</a>
                </li> */}
                <li className="mobile-menu-item">
                  <a className="mobile-menu-item-link" onClick={menuItemClick} href={ process.env.REACT_APP_MARKETING_URL + '/what-is-unpakt'}> About Us</a>
                </li>
                <li className="mobile-menu-item">
                  <a className="mobile-menu-item-link" onClick={menuItemClick} href={ process.env.REACT_APP_MARKETING_URL + '/how-it-works'}>How It Works</a>
                </li>
                <li className="mobile-menu-item">
                  <a className="mobile-menu-item-link" onClick={menuItemClick} href={ process.env.REACT_APP_MARKETING_URL + '/faq'}> FAQ</a>
                </li>
                <li className="mobile-menu-item">
                  <a className="mobile-menu-item-link" onClick={menuItemClick} href={ process.env.REACT_APP_MARKETING_URL + '/customer-service'}> Customer Support</a>
                </li>
                {/* <li className="mobile-menu-item">
                  <a className="mobile-menu-item-link" onClick={menuItemClick} href={ process.env.REACT_APP_MARKETING_URL + '/service-providers'}> For Service Providers</a>
                </li> */}
                { props.isLoggedIn &&
                  <li className="mobile-menu-item">
                    <span className="mobile-menu-item-link" onClick={signOut} > 
                      Log out
                    </span>
                  </li>
                }
              </ul>
              { !props.isLoggedIn &&
                <ul className="mobile-menu mobile-actions-menu clearfix" ng-if="!isLoggedIn()">
                  <li className="mobile-action-menu-item d-inline-block pull-left ">
                    <Link to="register" className="mobile-cta-button" onClick={toggleMobileMenu}> Sign Up</Link>
                  </li>
                  <li className="mobile-action-menu-item d-inline-block pull-right">
                    <Link to="/sign-in" className="mobile-cta-button" onClick={toggleMobileMenu}>
                      Login
                    </Link>
                  </li>
                </ul>
              }
            </div>
          </div>
        </nav>
        <div className="hamburger" onClick={toggleMobileMenu}>
          <span className="bar"></span>
        </div>
      </section>
      <div className="dimmer" onClick={toggleMobileMenu}/>
    </div>
  )
}

export default connect(mapStateToProps, {signOut})(Header)