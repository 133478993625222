import { GET_SUGGESTIONS, SELECT_SUGGESTION, SET_SUGGESTIONS, SET_SCRIPT_LOADING } from "../actionTypes";

export const getSuggestions = (query, useExact) => ({
  type: GET_SUGGESTIONS,
  payload: { query: query, useExact: useExact }
});

export const setSuggestions = (suggestions) => ({
  type: SET_SUGGESTIONS,
  payload: suggestions
});

export const selectSuggestion = (suggestion, key) => ({
  type: SELECT_SUGGESTION,
  payload: {suggestion: suggestion, key: key}
});

export const setScriptLoading = bool => ({
  type: SET_SCRIPT_LOADING,
  payload: bool
});