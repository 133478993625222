import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import ProgressWizard from '../common/ProgressWizard';
import PageNotFound from '../common/PageNotFound';
import { connect } from 'react-redux';

const AsyncInventory = lazy(() => import("../moving/inventory/Inventory"));
const AsyncInventoryOverview = lazy(() => import ("../moving/inventory/InventoryOverview"));
const AsyncDetails = lazy(() => import ("../moving/details/Details"));
const AsyncCompare = lazy(() => import ("../moving/compare/Compare"));
const AsyncBook = lazy(() => import ("../moving/book/Book"));
const AsyncPackingServices = lazy(() => import ("../moving/inventory/PackingServices"));
const AsyncSpecialHandling = lazy(() => import ("../moving/inventory/SpecialHandling"));
const AsyncReview = lazy(() => import ("../moving/review/Review"));
const AsyncCongrats = lazy(() => import ("../moving/congrats/Congrats"));

const mapStateToProps = ({movePlan, router}) => ({
  mpid: movePlan.currentMPID,
  isOverviewPage: router.location.pathname.indexOf('overview') >= 0
});

const MovingContainer = props => (
  <div className="moving-view-wrapper">
    { !props.isOverviewPage && <ProgressWizard/>}
    <Switch>
      <Route path="/moving/:mpid/inventory/packing-services" render={() => <Suspense fallback={<Loader/>}><AsyncPackingServices {...props}/></Suspense>}/>
      <Route path="/moving/:mpid/inventory/special-handling" render={() => <Suspense fallback={<Loader/>}><AsyncSpecialHandling {...props}/></Suspense>}/>
      <Route exact path="/moving/:mpid/inventory" render={() => <Suspense fallback={<Loader/>}><AsyncInventory {...props}/></Suspense>}/>
      <Route path="/moving/:mpid/details" render={() => <Suspense fallback={<Loader/>}><AsyncDetails {...props}/></Suspense>}/>
      <Route path="/moving/:mpid/compare" render={() => <Suspense fallback={<Loader/>}><AsyncCompare {...props}/></Suspense>}/>
      <Route path="/moving/:mpid/overview" render={() => <Suspense fallback={<Loader/>}><AsyncInventoryOverview {...props}/></Suspense>}/>
      <Route path="/moving/:mpid/review" render={() => <Suspense fallback={<Loader/>}><AsyncReview {...props}/></Suspense>}/>
      <Route path="/moving/:mpid/book" render={() => <Suspense fallback={<Loader/>}><AsyncBook {...props}/></Suspense>}/>
      <Route path="/moving/:mpid/congrats" render={() => <Suspense fallback={<Loader/>}><AsyncCongrats {...props}/></Suspense>}/>
      <Route component={PageNotFound}/>
    </Switch>
  </div>
);

export const Loader = () => <img alt="loading" style={{ margin: '40px auto'}} src="/img/compare_loader.gif"/>

export default connect(mapStateToProps)(MovingContainer);