import React, { useEffect } from 'react';
import  { connect } from 'react-redux';
import { Route, Switch } from "react-router-dom";
import PageNotFound from '../common/PageNotFound';
import Header from '../common/Header';
import SignIn from '../start/auth/SignIn';
import ForgotPassword from '../start/auth/ForgotPassword';
import Register from '../start/auth/Register';
import { checkCookies } from '../../redux/actions/auth';
import MovingContainer from '../moving/MovingContainer';
import Email from '../start/Email';
import Legal from '../common/Legal';

const mapStateToProps = ({movePlan}) => ({
  mpid: movePlan.currentMPID
});
const FullWidthLayout = props => {
  //eslint-disable-next-line
  useEffect(() => props.checkCookies(document.cookie),[])
  return (
    <div className="app-container">
      <Header/>
      <div className="mobile-app-container">
        <Switch>
          <Route exact path="/" component={() => {
            window.location.href = process.env.REACT_APP_MARKETING_URL;
            return null;
          }}/>
          <Route exact path="/sign-in" component={SignIn}/>
          <Route exact path="/register" component={Register}/>
          <Route exact path="/forgot-password" component={ForgotPassword}/>
          <Route exact path="/email" component={Email}/>
          <Route exact path="/legal" component={Legal}/>
          <Route path="/moving/:mpid/" component={MovingContainer}/>
          <Route component={PageNotFound}/>
        </Switch>
      </div>
    </div>
  )
}

export default connect(mapStateToProps,{ checkCookies })(FullWidthLayout)
