import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';
import { Formik } from 'formik';
import { resetPassword, setResetEmail, setErrorMessage, setAuthSubmitting } from '../../../redux/actions/auth'
import cx from 'classnames';
import ReactLoading from 'react-loading';

const mapStateToProps = ({auth}) => ({
  apiErrorText: auth.authErrorText,
  submitting: auth.submitting,
  resetEmail: auth.resetEmail
})

const ForgotPassword = props => {
  useEffect(() => {
    props.setResetEmail(undefined);
    props.setAuthSubmitting(false); //eslint-disable-next-line
  }, []);
  return (
    <div className="container moving-view-wrapper">
      <Row>
        <div className="mt-4 mb-3 col-12 text-center font-weight-bold">
          <h1>Forgot Password</h1>
        </div>
      </Row>
      <Row>
        <div className="col-12">
          <Formik
          initialValues={{
            email: ''
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validate={values => {
            let errors = {};
            if(!values.email) { 
              errors.email = 'Email is required' 
            } else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {  errors.email = `"${values.email}" is not a valid email address.`}
            return errors
          }}
          handleSubmit={(e) =>  e.preventDefault() }
          onSubmit={(values, {setSubmitting}) => {
            props.resetPassword(values.email)
            setSubmitting(false)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm
          }) => (
            <div className="moving-view-wrapper container panel p-3">
              <Container>
                <Row>
                  { props.resetEmail === 'success' 
                    ? (
                      <div className="col-12 ">
                        <p className="text-green mb-0">
                          Success! Check your email for instructions.
                        </p>
                      </div>
                    ) : (
                      <form onSubmit={handleSubmit} noValidate>
                        <div className="col-12 ">
                          <p className="">
                            Enter your email below and we'll send you a reset password instructions.
                          </p>
                        </div>
                        <hr/>
                        { errors.email && touched.email &&
                          <div className="col-xs-12">
                            <div className="well error-well col-md-12 p-t-5 p-b-5 m-b-10">
                              <p className="f-s-xs-14 m-b-0">{errors.email}</p>
                            </div>
                          </div>
                        }
                        { !!props.apiErrorText &&
                          <div className="col-xs-12">
                            <div className="well error-well col-md-12 p-t-5 p-b-5 m-b-10">
                              <p className="f-s-xs-14 m-b-0">{props.apiErrorText}</p>
                            </div>
                          </div>
                        }
                        <>
                          <div className="col-xs-12 m-t-20 required-input-container">
                            <input  type="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onFocus={() => {
                                      props.setErrorMessage('');
                                      resetForm();
                                    }}
                                    value={values.email}
                                    name="email"
                                    placeholder="Registration Email"
                                    autoComplete="email" 
                                    autoFocus={true}
                                    className={cx('required-input-container', {'error': touched.email && errors.email})}/>
                          </div>
                          <button 
                            type="submit"
                            className="btn btn-block btn-secondary filled login no-transition"
                            disabled={isSubmitting || props.submitting}
                            onClick={handleSubmit}
                          >
                            { props.submitting || isSubmitting
                              ? <ReactLoading className="m-auto" type={"spokes"} color={"#fff"} height={24} width={24}/>
                              : 'EMAIL RESET INSTRUCTIONS'
                            }
                          </button>
                        </>
                      </form>
                    )
                  }
                </Row>
              </Container>  
            </div>
          )}
          </Formik>
        </div>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps,{resetPassword, setResetEmail, setErrorMessage, setAuthSubmitting})(ForgotPassword)