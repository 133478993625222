import React, { useEffect } from "react";
import { withRouter } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import FullWidthLayout from './components/layouts/FullWidthLayout';
import AnalyticsWrapper from './components/common/AnalyticsWrapper';
import './scss/index.scss'

const App = () => {
  useEffect( () => {
    return () => {
      try {
        localStorage.removeItem('persist:root');
      } catch(e) {
        return false;
      }
    } //eslint-disable-next-line
  },[])
  return (
    <AnalyticsWrapper>
      <div className="font-proxima">
        <FullWidthLayout/>
      </div>
    </AnalyticsWrapper>
  )
}

export default withRouter(App);