import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { signIn, setErrorMessage, setAuthSubmitting } from '../../../redux/actions/auth';
import { Container, Row } from 'reactstrap';
import cx from 'classnames';
import has from 'lodash-es/has';
import ReactLoading from 'react-loading';
import { getLastStep } from '../../../redux/reducers/funnel';

const mapStateToProps = ({auth, funnel}) => ({
  apiErrorText: auth.authErrorText,
  submitting: auth.submitting,
  isLoggedIn: has(auth, 'user'),
  userEmail: has(auth, 'user') ? auth.user.email : null,
  lastMPID: auth.lastMPID,
  progressBarSteps: funnel.progressBarSteps
});

const SignIn = props => {
  useEffect(() => {
    props.setErrorMessage('');
    props.setAuthSubmitting(false); //eslint-disable-next-line
  }, []);
  return (
    <div className="container moving-view-wrapper">
      <Row>
        <div className="mt-4 mb-3 col-12 text-center font-weight-bold">
          <h1>Sign In</h1>
        </div>
      </Row>
      { props.isLoggedIn ? (
          <Row>
            <div className="col-12 moving-view-wrapper  pb-4 pt-4 ">
                <div className="col-12 panel text-center pt-3 pb-3">
                  <h2>You're logged in as {props.userEmail}</h2>
                  <hr/>
                  <a className="d-block " href={`${process.env.REACT_APP_MARKETING_URL}`}>Start a new Move</a>
                  {/* TODO: make sure that lastMPID is set via local storage */}
                  { !!props.lastMPID &&
                    <Link to={`moving/${props.lastMPID}/review`} className="mt-2">
                      Continue Last Move Plan
                    </Link>
                  }
                </div>
            </div>
          </Row>
        ) : (
          <Row>
            <div className="col-12">
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                  showPassword: false
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                  let errors = {};
                  if(!values.email) { 
                    errors.email = 'Email is required' 
                  } else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {  
                    errors.email = `"${values.email}" is not a valid email address.`
                  }
                  if(!values.password) errors.password = 'Password is required'
                  return errors
                }}
                handleSubmit={(e) =>  e.preventDefault()}
                onSubmit={(values, {setSubmitting}) => {
                  
                  if(!!props.lastMPID) props.signIn(values, { redirect: `/moving/${props.lastMPID}/${getLastStep(props.progressBarSteps)}`});
                  else props.signIn(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setErrors
                }) => (
                  <div className="moving-view-wrapper panel pb-4 pt-4 pl-0 pr-0">
                    <form onSubmit={handleSubmit} noValidate>
                      <Container>
                        <Row >
                          <div className="col-12 pl-0 pr-0">
                            <Container>
                              <Row>
                                { errors.email && touched.email &&
                                  <div className="col-12 col-sm-8 offset-sm-2 mb-2">
                                    <div className="well error-well p-t-5 p-b-5 ">
                                      <p className="f-s-xs-14 text-center mt-0 mb-0">{errors.email}</p>
                                    </div>
                                  </div>
                                }
                                <div className="col-12 mb-1">
                                  <input  type="email" 
                                          className={cx('required-input-container', {'error': touched.email && errors.email})}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          onFocus={() => {
                                            setErrors({});
                                            props.setErrorMessage('');
                                          }}
                                          value={values.email}
                                          name="email" 
                                          placeholder="Email" 
                                          autoComplete="email" 
                                          required
                                  />
              
                                </div>
                                { errors.password && touched.password &&
                                  <div className="col-12 col-sm-8 offset-sm-2 mb-2">
                                    <div className="well error-well p-t-5 p-b-5 ">
                                      <p className="f-s-xs-14 text-center mt-0 mb-0">{errors.password}</p>
                                    </div>
                                  </div>
                                }
                                <div className="col-12 required-input-container mb-0">
                                  <input  type={values.showPassword ? 'text' : 'password'}
                                          className={cx('required-input-container mb-3', {'error': touched.password && errors.password})}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          onFocus={() => {
                                            setErrors({});
                                            props.setErrorMessage('');
                                          }}
                                          value={values.password}
                                          name="password" 
                                          placeholder="Password" 
                                          autoComplete="new-password" 
                                          required
                                  />
                                </div>
                              </Row>
                            </Container>
                          </div>
                        </Row>
                        <Row>
                          <div className="col-8">
                            <Link className="pl-1" to="/forgot-password">Forgot Password?</Link>
                          </div>
                          <div className="col-4">
                            <span className="align-top pr-2">Show</span>
                            <span className={cx('apple-switch-container', {'checked': values.showPassword})}>
                              <span className={cx('apple-switch-switch', {'checked': values.showPassword})}>
                                <input  type="checkbox"
                                        className="apple-switch-switch"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.showPassword}
                                        name="showPassword"
                                />
                              </span>
                            </span>
                          </div>
                        </Row>
                        
                        { !!props.apiErrorText &&
                          <Row>
                            <div className="col-xs-12 col-sm-8 offset-sm-2  ">
                              <hr/>
                              <div className="well error-well p-t-5 p-b-5 ">
                                <p className="f-s-xs-14 text-center mt-0 mb-0">{props.apiErrorText}</p>
                              </div>
                            </div>
                          </Row>
                        }

                        <Row>
                          <div className="col-12 mt-2">
                            <button
                              type="submit"
                              disabled={props.submitting}
                              onClick={handleSubmit}
                              className="btn btn-block btn-secondary filled login no-transition"
                            >
                            { props.submitting
                              ? <ReactLoading className="m-auto" type={"spokes"} color={"#ddd"} height={20} width={20}/>
                              : 'SIGN IN'
                            }
                            </button>
                            </div>
                        </Row>
                      </Container>
                    </form>
                  </div>
                )}
              </Formik>
            </div>
          </Row>
        )
      }
    </div>
  );
};

export default connect(mapStateToProps, {signIn, setErrorMessage, setAuthSubmitting})(SignIn)