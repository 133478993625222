import {
  BOOK,
  CHECK_PROMO, INIT_BOOK_PAGE,
  SET_BOOK_CONTACT, SET_CARD_ERRORS,
  SET_CLIENT_TOKEN,
  SET_HOSTED_FIELDS_INSTANCE,
  SET_USER, SUBMIT_BOOK, TOGGLE_IS_SUBMITTING, SET_BOOK_NONCE, SET_PROMO_LOADING, SET_PAYPAL_COMPLETE, SET_PAYPAL_EMAIL, CANCEL_PAYPAL, SET_BOOK_LOADING,  
} from '../actionTypes';

export const initBookPage = (movePlanId) => ({
  type: INIT_BOOK_PAGE,
  payload: movePlanId
});

export const checkPromo = (couponCode) => ({
  type: CHECK_PROMO,
  payload: couponCode
});

export const setClientToken = (token) => ({
  type: SET_CLIENT_TOKEN,
  payload: token
});

export const setCardErrors = (errors) => ({
  type: SET_CARD_ERRORS,
  payload: errors
});

export const setUser = (user) =>({
  type:SET_USER,
  payload: user
});

export const setHostedFieldsInstance = (instance) => ({
  type: SET_HOSTED_FIELDS_INSTANCE,
  payload: instance
});

export const book = (payload) => ({
  type: BOOK,
  payload: payload
});

export const submitBook = (payload) => ({
  type: SUBMIT_BOOK,
  payload: payload
});

export const setBookContact = (payload) => ({
  type: SET_BOOK_CONTACT,
  payload: payload
});

export const toggleIsSubmitting = () => ({
  type: TOGGLE_IS_SUBMITTING,
});

export const setBookNonce = nonce => ({
  type: SET_BOOK_NONCE,
  payload: nonce
});

export const setPromoLoading = bool => ({
  type: SET_PROMO_LOADING,
  payload: bool
});

export const setPaypalComplete = bool => ({
  type: SET_PAYPAL_COMPLETE,
  payload: bool
});

export const setPaypalEmail = email => ({
  type: SET_PAYPAL_EMAIL,
  payload: email
});

export const cancelPaypal = () => ({
  type: CANCEL_PAYPAL
});

export const setBookLoading = bool => ({
  type: SET_BOOK_LOADING,
  payload: bool
});