import React from 'react';
import {connect} from 'react-redux';
import {gotoMyInventory, gotoDetails, gotoCompare, gotoBook} from '../../redux/actions/funnel';

const mapStateToProps = state => ({
  currentStep: state.funnel.currentStep
});

const ProgressWizard = props => {
  const gotoPage = page => {
    switch(page) {
      case 'INVENTORY':
        props.gotoMyInventory(); break;
      case 'DETAILS':
        props.gotoDetails(); break;
      case 'COMPARE':
        props.gotoCompare(); break;
      case 'BOOK':
        props.gotoBook(); break;
      default: break;
    }
  }
  return (
    <div className="progress-bar">
      <ol className="list-inline text-center m-b-0 p-t-10 p-b-10 f-s-xs-13">
        <li className={`progress-bar-step step-enabled ${(props.currentStep === 'INVENTORY' || props.currentStep === '') && 'step-active'}`}>
          <div className="step-link" onClick={() => gotoPage('INVENTORY')}>
            <svg id="progress-1" viewBox="0 0 22.9 24">
              <path className="st0" d="M11.5,24C5,23.8-0.1,18.5,0,12C-0.1,5.5,5,0.2,11.5,0c6.5,0.2,11.6,5.5,11.5,12C23.1,18.5,17.9,23.8,11.5,24z
                M11.5,1C5.5,1.2,0.9,6.1,1,12c-0.1,5.9,4.5,10.8,10.5,11c5.9-0.2,10.6-5.1,10.5-11C22.1,6.1,17.4,1.2,11.5,1z"/>
              <path className="st0" d="M8.9,10c0.3,0,0.5,0,0.8-0.1c0.2,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.2,0.5-0.4c0.2-0.2,0.3-0.4,0.3-0.6h1.1v8
                h-1.4V11h-2L8.9,10z"/>
            </svg>
            {/* <span className="f-s-xs-18 icon-ic_progress1" ng-if="::!vm.isFinished('inventory')"></span> */}
            {/* <span className="icon-finished" ng-if="::vm.isFinished('inventory')">&#10003;</span> */}
            Inventory
          </div>
        </li>
        <li className={`progress-bar-step step-enabled ${ (props.currentStep === 'DETAILS') &&'step-active'}`}>
          <span className="step-link" onClick={() => gotoPage('DETAILS')}>
            <svg id="progress-2" viewBox="0 0 22.9 24">
              <title>icons_v1</title>
              <path className="st0" d="M11.5,24C5,23.8-0.1,18.5,0,12C-0.1,5.5,5,0.2,11.5,0c6.5,0.2,11.6,5.5,11.5,12C23.1,18.5,17.9,23.8,11.5,24z M11.5,1C5.5,1.2,0.9,6.1,1,12c-0.1,5.9,4.5,10.8,10.5,11c5.9-0.2,10.6-5.1,10.5-11C22.1,6.1,17.4,1.2,11.5,1z"/>
              <path className="st0" d="M14.2,16.7H8.7c0-0.6,0.2-1.2,0.5-1.8c0.3-0.5,0.8-1,1.3-1.3c0.3-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.5-0.4,0.8-0.6 c0.2-0.2,0.4-0.4,0.6-0.7c0.2-0.2,0.2-0.5,0.2-0.8c0-0.2,0-0.3,0-0.5c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4 c-0.2-0.1-0.5-0.2-0.7-0.1c-0.2,0-0.5,0-0.7,0.2c-0.2,0.1-0.3,0.3-0.4,0.4c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.3-0.1,0.5-0.1,0.8H8.9 c0-0.4,0-0.8,0.1-1.2c0.1-0.4,0.3-0.7,0.5-1c0.2-0.3,0.5-0.5,0.9-0.6c0.8-0.3,1.6-0.3,2.4,0c0.3,0.1,0.6,0.4,0.8,0.6 c0.2,0.2,0.3,0.5,0.4,0.8c0.1,0.3,0.1,0.5,0.1,0.8c0,0.3,0,0.6-0.2,0.9c-0.1,0.3-0.2,0.5-0.4,0.7c-0.2,0.2-0.4,0.4-0.6,0.6 c-0.2,0.2-0.4,0.4-0.7,0.5l-0.7,0.5c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.1-0.2,0.3-0.3,0.5h3.9L14.2,16.7z"/>
            </svg>        
            {/* <span className="icon-finished" ng-if="::vm.isFinished('details')">&#10003;</span> */}
            Details
          </span>
        </li>
        <li className={`progress-bar-step step-enabled ${props.currentStep === 'COMPARE' && 'step-active'}`}>
          <span className="step-link" onClick={() => gotoPage('COMPARE')}>
            <svg style={{ fill: "inherit"}} id="progress-3" viewBox="0 0 22.9 24">
              <title>icons_v1</title><path className="st0" d="M11.5,24C5,23.8-0.1,18.5,0,12C-0.1,5.5,5,0.2,11.5,0c6.5,0.2,11.6,5.5,11.5,12C23.1,18.5,17.9,23.8,11.5,24z M11.5,1C5.5,1.2,0.9,6.1,1,12c-0.1,5.9,4.5,10.8,10.5,11c5.9-0.2,10.6-5.1,10.5-11C22.1,6.1,17.4,1.2,11.5,1z"/><path className="st0" d="M10.9,12c0.2,0,0.4,0,0.6,0c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.2-0.4,0.2-0.6 c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.2-0.6-0.3-0.9-0.3c-0.4,0-0.8,0.2-1,0.5c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0.2-0.1,0.4-0.1,0.6H8.8 c0-0.4,0.1-0.8,0.2-1.2c0.1-0.3,0.3-0.6,0.5-0.9C9.8,9,10,8.8,10.4,8.7c0.3-0.1,0.7-0.2,1.1-0.2c0.3,0,0.6,0,0.9,0.1 c0.3,0.1,0.6,0.2,0.8,0.4c0.5,0.4,0.8,1,0.8,1.6c0,0.4-0.1,0.7-0.3,1.1c-0.2,0.3-0.5,0.5-0.9,0.7l0,0c0.4,0.1,0.8,0.3,1.1,0.7  c0.5,0.7,0.5,1.6,0.2,2.4c-0.1,0.3-0.4,0.6-0.6,0.8c-0.3,0.2-0.6,0.4-0.9,0.5c-0.8,0.2-1.6,0.2-2.3,0c-0.3-0.1-0.6-0.3-0.9-0.6 c-0.2-0.3-0.4-0.6-0.5-0.9c-0.1-0.4-0.2-0.8-0.2-1.2h1.3c0,0.4,0.1,0.9,0.4,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4 c0.3-0.3,0.5-0.7,0.4-1.1c0-0.3,0-0.5-0.2-0.8c-0.1-0.2-0.3-0.3-0.5-0.4C12.1,13,11.9,13,11.7,13c-0.3,0-0.5,0-0.8,0L10.9,12z"/>
            </svg>
            {/* <span className="icon-finished" ng-if="::vm.isFinished('compare')">&#10003;</span> */}
            Compare
          </span>
        </li>
        <li className={`progress-bar-step step-enabled ${(props.currentStep === 'BOOK' || props.currentStep === 'REVIEW') && 'step-active'}`}>
          <span className="step-link" onClick={() => gotoPage('BOOK')}>
            <svg style={{ fill: "inherit"}} id="progress-4" viewBox="0 0 22.9 24">
              <g><path className="st0" d="M12,14.4H8.2v-1.2c0.7-1.3,1.5-2.6,3.2-5.1h2v5.2h1v1.1h-1v1.5H12V14.4z M9.4,13.3h2.6V9.1h0 C10.8,10.9,10.1,12.1,9.4,13.3L9.4,13.3z"/></g><title>icons_v1</title><path className="st0" d="M11.5,24C5,23.8-0.1,18.5,0,12C-0.1,5.5,5,0.2,11.5,0C18,0.2,23.1,5.5,23,12C23.1,18.5,17.9,23.8,11.5,24z M11.5,1C5.5,1.2,0.9,6.1,1,12c-0.1,5.9,4.5,10.8,10.5,11c5.9-0.2,10.6-5.1,10.5-11C22.1,6.1,17.4,1.2,11.5,1z"/>
            </svg>
            {/*<span className="icon-finished" ng-if="::vm.isFinished('book')">&#10003;</span>} */}
            Book
          </span>
        </li>
      </ol>
    </div>
  )
}

export default connect(mapStateToProps, {gotoMyInventory, gotoDetails, gotoCompare, gotoBook})(ProgressWizard);